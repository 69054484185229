import Api from 'cox_city_services_platform_api';

import { setErrorEventData } from '@cox2m/city-services-ui-components/src/funcs';

const {
  ApiClient,
  EventApi,
} = Api;
/**
 * Initialize the API client
 */
const initApiClient = () => {
  const apiClient = new ApiClient();
  apiClient.basePath = 'SMART_CITIES_API_HOST';
  apiClient.defaultHeaders = {
    'Access-Control-Allow-Origin': '*'
  };
  return apiClient;
};
const apiClient = initApiClient();
const eventApi = new EventApi(apiClient);
/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = (token) => {
  apiClient.authentications["API_TOKEN"].apiKey = token;
};
/**
 * Retrieve hardware places
 * @param {string} token
 * @param {string} filter
 * @param {object} opts
 *
 */
export const getEventsByFilterParking = async (token, filter, since, opts) => {
  try {
    setToken(token);
    const response = await eventApi.getEventsByFilterParking(filter, since, opts);
    return { fulfilled: true, collection: response.events, paging: response.paging }
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-events-by-filter-parking');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};
