<script>
  import ThoughtSpotComponent from '@cox2m/city-services-ui-components/src/components/thoughtspot/ThoughtspotWrapper.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {menuOpts, user} from '../stores';

</script>

<Modal>
  <MainScreen title="Home - Analytics" menuOpt={$menuOpts} user={$user} appSlug="curbside" showAlertsBanner={false}>
    <div slot="main-dashboard" class="h-100">
      <ThoughtSpotComponent liveboardId='CUBRIDE_TAXI_ZONE_REPORT_ID' />
    </div>
  </MainScreen>
</Modal>
