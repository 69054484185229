<script>
  import ScalableDataTable from '@cox2m/city-services-ui-components/src/components/ScalableDataTable.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectInput from '@cox2m/city-services-ui-components/src/forms/SelectInput.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Datepicker from 'svelte-calendar/src/Components/Datepicker.svelte';
  import SidebarGallery from './Components/SidebarGallery.svelte';
  import RideshareImage from './Components/RideshareImage.svelte';
  import PlateImage from './Components/PlateImage.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {getEventsByFilterParking} from '../../actions.js';
  import {sidebarStore, menuOpts, user} from '../../stores';
  import {location} from 'svelte-spa-router';
  import {onMount} from 'svelte';

  export let parkingViolationsOnly = $location === '/parking-violations';

  const TOKEN = getCookieByName(`${'ENV'}_token`);
  const eventFilter = 'name:Vehicle Left Space';
  const eventName = 'Vehicle Left Space';
  const clientOffsetInHours = new Date().getTimezoneOffset() * (-1 / 60);
  const INITIAL_ITEMS_PER_PAGE = 20;

  let paginationOptions = {
    limit: INITIAL_ITEMS_PER_PAGE,
    offset: 0
  };
  let paginationResponse;
  let sortString = 'createdAt:desc';

  const addOffsetString = () => {
    let offset;
    if (clientOffsetInHours === 0) {
      offset = '+00:00';
    } else if (clientOffsetInHours < 0) {
      Number.isInteger(clientOffsetInHours)
        ? (offset =
            '-' +
            ((clientOffsetInHours * -1).toString().padStart(2, 0) + ':00'))
        : (offset =
            '-' +
            ((Math.floor(clientOffsetInHours) * -1).toString().padStart(2, 0) +
              ':30'));
    } else {
      Number.isInteger(clientOffsetInHours)
        ? (offset =
            '+' + (clientOffsetInHours.toString().padStart(2, 0) + ':00'))
        : (offset =
            '+' +
            (Math.floor(clientOffsetInHours)
              .toString()
              .padStart(2, 0) +
              ':30'));
    }
    return offset;
  };

  const now = new Date();
  const today = new Date(now).setDate(now.getDate());

  let selectedDate = new Date(today);
  let selectedParkingViolations;
  let tempSearchCriteria;
  let selectedRideshare;
  let selectedEventType;
  let searchCriteria;
  let selectedZone;

  let emptyParkingViolations = false;
  let loading = true;
  let error = false;
  let temprows = [];

  let rows = temprows;

  const columns = [
    {
      label: 'License plate',
      key: 'license_plate',
      type: 'text',
      Component: PlateImage,
      notSortable: true
    },
    {
      label: 'Stall name',
      key: 'stall_name',
      type: 'text',
      notSortable: true
    },
    {label: 'Arrival time', key: 'arrival_time', type: 'date', notSortable: true},
    {label: 'Departure time', key: 'departure_time', type: 'date', notSortable: true},
    {
      label: 'Rideshare',
      key: 'rideshare',
      type: 'text',
      Component: RideshareImage,
      notSortable: true
    },
    {label: 'Tag', key: 'tag', type: 'text', notSortable: true},
    {label: 'Duration of stay (mins)', key: 'duration_of_stay', type: 'number', notSortable: true}
  ];

  const zoneOptions = [
    {label: 'All zones', value: null},
    {label: 'Taxi zone', value: 'taxi-zone'},
    {label: 'ALU zone', value: 'alu-zone'}
  ];

  const eventTypeOptions = [
    {label: 'All event types', value: null},
    {label: 'Double parking', value: 'double-parking'},
    {label: 'Car in taxi zone', value: 'taxi-zone;car'}
  ];
  const typeOptions = [
    {label: 'All types', value: 'all'},
    {label: 'Parking violations', value: 'violations'},
    {label: 'Non parking violations', value: 'non-violations'}
  ];

  const rideshareOptions = [
    {label: 'All types', value: null},
    {label: 'Uber', value: 'uber'},
    {label: 'Lyft', value: 'lyft'}
  ];

  const cleanSidebarData = () =>
    sidebarStore.sidebarData({
      visibility: false,
      parking_violation: null
    });

  const getSearchCriteria = () => {
    let search = `${searchCriteria ? searchCriteria : ''}`;
    if (selectedZone && selectedZone != 'null') {
      search += `;${selectedZone}`;
    }
    if (selectedEventType && selectedEventType != 'null') {
      search += `;${selectedEventType}`;
    }
    if (selectedRideshare && selectedRideshare != 'null') {
      search += `;${selectedRideshare}`;
    }
    return search;
  };

  const getEventTypeLabel = entry => {
    let tag = '';

    if (
      entry.data.arrival.space &&
      entry.data.arrival.space.id.includes('taxi-zone') &&
      entry.data.arrival.vehicle.type !== 'taxi'
    ) {
      tag = 'non taxi';
    } else if (
      entry.data.arrival.space &&
      entry.data.arrival.space.id.includes('double-parking')
    ) {
      tag = 'double parking';
    } else if (entry.value < 3) {
      tag = 'non violation';
    } else if (entry.value >= 3) {
      tag = 'parking violation';
    }

    return tag;
  };

  const formatDateString = date =>
    date
      .toLocaleString()
      .replace('T', ' ')
      .split('.')[0];

  const buildRow = item => {
    return {
      id: item.id,
      arrival_time: formatDateString(item.data.arrival.occurred_at),
      departure_time: formatDateString(item.data.departure.occurred_at),
      duration_of_stay: item.value || '0',
      license_plate: {
        plate: item.data.departure.vehicle.license_plate,
        image_url:
          [
            ...(item.data.arrival.typed_photos || []),
            ...(item.data.departure.typed_photos || [])
          ].filter(photo => photo.type === 'plate')[0] || null
      },
      stall_name: item.data.departure.space.label,
      images: {
        vehicle: [
            ...(item.data.arrival.typed_photos || []),
            ...(item.data.departure.typed_photos || [])
          ].filter(photo => photo.type === 'plate' || photo.type === 'vehicle') || null,
        arrival: item.data.arrival.typed_photos
          ? [...item.data.arrival.typed_photos].filter(photo => photo.type === 'front' || photo.type === 'back')
          : [],
        departure: item.data.departure.typed_photos
          ? [...item.data.departure.typed_photos].filter(photo => photo.type === 'front' || photo.type === 'back')
          : []
      },
      rideshare:
        item.data.arrival.vehicle && item.data.arrival.vehicle.subclass !== 'NA'
          ? item.data.arrival.vehicle.subclass
          : '',
      tag: getEventTypeLabel(item)
    };
  };

  const getFilteredEventsWithDate = async date => {
    loading = true;
    cleanSidebarData();

    if (date instanceof Date) {
      date =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    }

    let processedInitialDate = `${date}T00:00:00.000` + addOffsetString();
    let processedFinalDate = `${date}T23:59:59.000` + addOffsetString();

    const filterParkingTags = () => {
      if (selectedParkingViolations === 'violations' || parkingViolationsOnly) {
        temprows = temprows.filter(row => row.tag !== 'non violation');
      } else if (selectedParkingViolations === 'non-violations') {
        temprows = temprows.filter(row => row.tag === 'non violation');
      }
      rows = temprows;
    };

    let criteria = getSearchCriteria();

    const response = await getEventsByFilterParking(
      TOKEN,
      eventFilter,
      processedInitialDate,
      {
        until: processedFinalDate,
        name: eventName,
        limit: paginationOptions.limit,
        offset: paginationOptions.offset,
        ...(criteria != '' && {search: getSearchCriteria()})
      }
    );

    if (response.fulfilled) {
      if (response.collection) {
        temprows = [];
        response.collection.forEach(item => {
          temprows.push(buildRow(item));
        });

        filterParkingTags();
      }
      emptyParkingViolations =
        !response.collection || (response && !response.collection.length);
      paginationResponse = response.paging;
    } else {
      error = true;
    }
    loading = false;
  };

  const handleSidebarClick = row => {
    $sidebarStore.visibility && row.id === $sidebarStore.parking_violation.id
      ? sidebarStore.sidebarData({
          visibility: false,
          parking_violation: null
        })
      : sidebarStore.sidebarData({
          visibility: true,
          parking_violation: row
        });
  };

  const cleanRowSelection = e => {
    rows.map(row => {
      if (row.id === e.detail.id) {
        row.rowSelected = false;
        let rows = document.getElementsByClassName('highlighted-row');
        Array.from(rows).forEach(row =>
          row.classList.remove('highlighted-row')
        );
      }
    });
  };

  onMount(() => {
    sidebarStore.sidebarData({
      visibility: false,
      images: []
    });
  });

  const handleFetchDataEvent = async e => {
    loading = true;
    paginationOptions = e.detail.dataPositioning;
    await getFilteredEventsWithDate(selectedDate);
  };

  const updateSortingField = async e => {
    sortString = e.detail.sortString;
    await getFilteredEventsWithDate(selectedDate);
  };

  $: getFilteredEventsWithDate(
    selectedDate,
    selectedEventType,
    selectedZone,
    selectedParkingViolations,
    selectedRideshare,
    searchCriteria,
    parkingViolationsOnly
  );

  $: parkingViolationsOnly = ($location === '/parking-violations');

  const thirtyDays= 1000 * 60 * 60 * 24 * 30;
  const calendarStartDate = new Date(Date.now() - thirtyDays);
</script>

<style>
  :global(#parking-violations .trigger .calendar-button) {
    border-radius: 3px;
    box-shadow: unset;
  }
  :global(#calendar-container .contents-wrapper) {
    margin-top: 150px;
    margin-left: var(--cox2m-spacing-10-units);
    z-index: 100;
  }
  :global(#calendar-container .datepicker .trigger div > button) {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    border: solid var(--cox2m-brd-w-1) var(--cox2m-clr-neutral-400);
    padding: var(--cox2m-spacing-3-units) var(--cox2m-spacing-5-units);
  }
  :global(#parking-violations-table) {
    text-align: center;
  }

  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }

  #parking-violations{
    padding: var(--cox2m-spacing-4-units);
  }

  #filters-container {
    display: grid;
    grid-auto-rows: 100px;
    grid-template-columns: minmax(320px, 330px) minmax(320px, 330px) minmax(
        320px,
        330px
      );
    width: calc(100% - 380px);
  }

  #search-input-container {
    max-width: 865px;
  }

  @media only screen and (max-width: 1640px) {
    #filters-container {
      grid-template-columns: minmax(320px, 330px) minmax(320px, 330px);
    }
  }

  @media only screen and (max-width: 1280px) {
    #filters-container {
      grid-template-columns: 1fr !important;
      width: 100%;
    }
  }
</style>

<MainScreen title="Parking Violations" menuOpt={$menuOpts} user={$user} appSlug="curbside" showAlertsBanner={false}>
  <div slot="main-dashboard" class="dashboard-component-container">
    <div id="parking-violations">
      <div id="title-container">
        <h2>{parkingViolationsOnly ? 'Parking Violations' : 'Parking Sessions'}</h2>
      </div>
      <div id="search-input-container">
        <TextInput
          id="logs-table-search-input"
          icon="search"
          customContainerClass="shadow-sm mb-2"
          placeholder="search by event data"
          bind:value={tempSearchCriteria}
          onKeyPress={e => e.key === 'Enter' && (searchCriteria = tempSearchCriteria)} />
      </div>
      <div
        id="filters-container"
        class="row align-items-end {$sidebarStore.visibility ? 'open-sidebar' : 'closed-sidebar'}">
        <div id="calendar-container" class="col">
          <p class="mt-3 mb-0">
            <strong>Check parking sessions of:</strong>
          </p>
          <Datepicker
            bind:selected={selectedDate}
            end={new Date(today)}
            start={calendarStartDate}
            format={'#{l}, #{F} #{j}, #{Y}'}
            highlightColor="#var(--cox2m-clr-brand-500)"
            dayBackgroundColor="var(--cox2m-clr-neutral-white)"
            dayTextColor="var(--cox2m-clr-neutral-700)"
            dayHighlightedBackgroundColor="var(--cox2m-clr-brand-500)"
            dayHighlightedTextColor="var(--cox2m-clr-neutral-white)"
            style="width: 100%;" />
        </div>
        <div class="col">
          <p class="mb-0">
            <strong>Zone:</strong>
          </p>
          <SelectInput
            customClass="selector"
            id="zone-selection"
            value
            options={zoneOptions}
            onChange={e => {
              selectedZone = e.currentTarget.value;
            }} />
        </div>
        <div class="col">
          <p class="mb-0">
            <strong>Event type:</strong>
          </p>
          <SelectInput
            customClass="selector"
            id="type-selection"
            value
            options={eventTypeOptions}
            onChange={e => {
              selectedEventType = e.currentTarget.value;
            }} />
        </div>
        {#if !parkingViolationsOnly}
          <div class="col">
            <p class="mb-0">
              <strong>Parking violations:</strong>
            </p>
            <SelectInput
              customClass="selector"
              id="type-selection"
              value
              options={typeOptions}
              onChange={e => {
                selectedParkingViolations = e.currentTarget.value;
              }} />
          </div>
        {/if}
        <div class="col">
          <p class="mb-0">
            <strong>Rideshare type:</strong>
          </p>
          <SelectInput
            customClass="selector"
            id="rideshare-selection"
            value
            options={rideshareOptions}
            onChange={e => {
              selectedRideshare = e.currentTarget.value;
            }} />
        </div>
      </div>
    </div>

    {#if loading}
      <div class="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    {:else if !error && emptyParkingViolations}
      <div class="d-flex justify-content-center">
        <h3 id="empty-parking-violations-message">
          There are no parking violations for the day: {selectedDate
            .toUTCString()
            .split(' ')
            .slice(0, 4)
            .join(' ')} with this search criteria
        </h3>
      </div>
    {:else if error}
      <div class="d-flex justify-content-center">
        <h3 id="error-parking-violations-message">
          We are sorry we had an error fetching parking violations of this day
        </h3>
      </div>
    {:else}
      <div id="parking-violations-table-top-container">
        <div
          class="d-flex justify-content-center mb-5 flex-column"
          id="parking-violations-table-container">
          <ScalableDataTable
            id="parking-violations-table"
            on:fetchData={handleFetchDataEvent}
            on:sortRequestByField={updateSortingField}
            {columns}
            pagination
            isLoading={loading}
            {paginationResponse}
            {rows}
            sort={sortString}
            fieldSelectionKey="id"
            dataPositioning={paginationOptions}
            itemsPerPage={paginationOptions.limit}
            onRowClick={row => handleSidebarClick(row)}
          />
        </div>
      </div>
      {/if}
      <SidebarGallery on:closeSidebarGallery={cleanRowSelection} />
    </div>
</MainScreen>
