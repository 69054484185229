<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import LocationTD from './components/LocationTD.svelte';
  import HardwareTD from './components/HardwareTD.svelte';
  import NetworkTD from './components/NetworkTD.svelte';
  import SystemTD from './components/SystemTD.svelte';
  import axios from 'axios';

  import {getCookieByName, setErrorEventData} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {menuOpts, user} from '../../stores';
  import {onMount} from 'svelte';

  const token = getCookieByName(`${'ENV'}_token`);

  const headers = {
    headers: {
      Authorization: token
    }
  };

  let rows = [];

  let loading = true;
  let error = false;

  const columns = [
    {label: 'Last updated', key: 'last_updated', type: 'date'},
    {label: 'Device name', key: 'name', type: 'text'},
    {label: 'Device ID', key: 'id', type: 'text'},
    {label: 'Kiosk mode', key: 'is_kiosk', type: 'text'},
    {label: 'Location', key: 'location', Component: LocationTD},
    {label: 'Network', key: 'network', Component: NetworkTD},
    {label: 'System', key: 'system', Component: SystemTD},
    {label: 'Hardware', key: 'hardware', Component: HardwareTD},
    {label: 'Last check in', key: 'last_reported', type: 'date'},
  ];

  const setError = () => {
    error = true;
    loading = false;
  };

  const setRows = devices => {
    rows = [];
    if (devices.length) {
      devices.forEach(device => {
        rows = [
          ...rows,
          {
            last_updated: new Date(device.data.lastnotified.replace('Z', '')).toLocaleString()
            .replace('T', ' ')
            .split('.')[0] || '',
            name: device.data.device.device_name || '',
            id: device.serialNumber,
            is_kiosk: device.data.device.is_kiosk ? 'Yes' : 'No',
            location: device.data,
            network: [`IP: ${device.data.device.ipaddress}`, `MAC: ${device.data.device.wifi_mac}`, `SSID: ${device.data.device.wifi_ssid}`],
            system: [`OS: ${device.data.device.os_name}`, `Version: ${device.data.device.os_version}`],
            hardware: [`Manufacturer: ${device.data.device.manufacture}`, `Model: ${device.data.device.model}`, `IMEI: ${device.data.device.imei}`],
            last_reported: new Date(device.data.lastreported.replace('Z', '')).toLocaleString()
          }
        ];
      });
    }
    loading = false;
  };

  onMount(async () => {
    try {
      const smallScreenDevices = await axios.get("SMART_CITIES_API_HOST/hardware-installation-data?hardwareType=SMALL_SCREEN", headers);
      setRows(smallScreenDevices.data.installations);
    } catch (error) {
      if(error.response !== 400){
        setErrorEventData(window.dispatchEvent, error.response, 'get-hardware-installation-data');
        setError()
      }
    }
  });
</script>

<style>
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
  .absolute-center {
    position: absolute;
    top: 50%;
    left: calc(50% - var(--cox2m-spacing-8-units));
  }
  :global(.filters-container .form-control) {
    max-width: 500px;
  }

  :global(#devices-table-container .container) {
    max-width: unset;
  }
</style>

<MainScreen title="Hexnode Devices" menuOpt={$menuOpts} user={$user} appSlug="curbside" showAlertsBanner={false}>
  <div slot="main-dashboard" class="dashboard-component-container">
    {#if loading}
      <div class="absolute-center d-inline-block">
        <LoadingSpinner />
      </div>
    {:else if error}
      <div class="w-100 text-center mt-5 ">
        <h3>We are sorry, we could not fetch the devices</h3>
      </div>
    {:else}
      <div>
        <h2 class="m-4">Devices</h2>
        {#if rows.length}
          <div class="mt-5" id="devices-table-container">
            <Table
              id="devices-table"
              {columns}
              {rows}
              pagination={true}
              itemsPerPage={20} />
          </div>
        {:else}
          <div class="w-100 text-center mt-5">
            <h3>There are no devices for this application</h3>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</MainScreen>
