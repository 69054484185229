<script>
  export let props = {};
</script>

<style>
  img {
    max-width: var(--cox2m-spacing-30-units);
  }
</style>

{#if !props.license_plate.image_url}
  {props.license_plate.plate}
{:else}
  <div>
    <span>{props.license_plate.plate}</span>
  </div>
  <img
    src={props.license_plate.image_url.url}
    alt={props.license_plate.plate + 'image'} />
{/if}
