<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {createEventDispatcher} from 'svelte';
  import {sidebarStore} from '../../../stores';

  let plateUrl;
  let vehicleUrl;

  const dispatch = createEventDispatcher();

  const cleanSidebarData = () => {
    sidebarStore.sidebarData({
      visibility: false,
      parking_violation: null
    });
  };

  const dispatchClosingSidebarEvent = () => {
    dispatch('closeSidebarGallery', {
      id: $sidebarStore.parking_violation
        ? $sidebarStore.parking_violation.id
        : null
    });
  };

  const handleSidebarClose = () => {
    dispatchClosingSidebarEvent();
    cleanSidebarData();
  };
  const handleVehicleImageChange = () => {
    plateUrl = null;
    vehicleUrl = null;
    if ($sidebarStore.parking_violation) {
      plateUrl = $sidebarStore.parking_violation.images.vehicle.filter(
        img => img.type === 'plate'
      )[0];

      vehicleUrl = $sidebarStore.parking_violation.images.vehicle.filter(
        img => img.type === 'vehicle'
      )[0];
    }
  };

  $: handleVehicleImageChange($sidebarStore.parking_violation);
</script>

<style>
  .parking-sidebar {
    border-left: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-500);
    max-width: 400px;
    width: 100%;
    position: absolute;
    height: calc(100% - var(--header-height));
    bottom: 0;
    right: 0;
    background: var(--cox2m-clr-neutral-white);
    overflow: auto;
    z-index: 100;
  }
  .close-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .parking-image {
    max-width: 350px;
    width: 100%;
  }
  .parking-image-link {
    width: 100%;
  }
  .ease-in {
    transition-timing-function: ease-in;
    transition: 0.75s;
    transform: translateX(0);
  }

  .ease-out {
    transition-timing-function: ease-out;
    transition: 0.75s;
    transform: translateX(130%);
    display: none;
    /* this is a partial fix to avoid breaking the view this view should be refactored to use the new sidebar */
  }
</style>

<div
  class="parking-sidebar p-3 pb-5 {$sidebarStore.visibility ? 'ease-in' : 'ease-out'}"
  id="parking-violations-sidebar-gallery">
  <div class="close-container">
    <span on:click={handleSidebarClose}>

      <Icon
        id="create-alert-close"
        icon="cross"
        size="var(--cox2m-spacing-6-units)"
        color="var(--cox2m-clr-brand-500)"
        className="btn p-0 m-0" />
    </span>
  </div>
  {#if $sidebarStore.parking_violation && $sidebarStore.parking_violation.stall_name}
    <h4 class="mb-2">
      {'Images from: ' + $sidebarStore.parking_violation.stall_name || ''}
    </h4>
  {/if}
  {#if $sidebarStore.parking_violation && !$sidebarStore.parking_violation.images.arrival.length && !$sidebarStore.parking_violation.images.departure.length && !$sidebarStore.parking_violation.images.vehicle.length}
    <h4 class="my-5 text-center" id="sidebar-gallery-empty-photos-message">
      There are no images for this entry
    </h4>
  {/if}
  {#if $sidebarStore.parking_violation && $sidebarStore.parking_violation.images.vehicle.length}
    <div class="images-section-container my-3">
      <h4>Vehicle Photos</h4>

      <div
        class="d-flex flex-column align-items-center"
        id="vehicle-images-container">
        {#if plateUrl}
          <a
            class="parking-image-link"
            href={plateUrl.url}
            target="_blank"
            rel="noopener noreferrer">
            <img
              class="parking-image my-1"
              src={plateUrl.url}
              alt={`plate image `} />
          </a>
        {/if}
        {#if vehicleUrl}
          <a
            class="parking-image-link"
            href={vehicleUrl.url}
            target="_blank"
            rel="noopener noreferrer">
            <img
              class="parking-image my-1"
              src={vehicleUrl.url}
              alt={`vehicle image `} />
          </a>
        {/if}
      </div>
    </div>
  {/if}
  {#if $sidebarStore.parking_violation && $sidebarStore.parking_violation.images.arrival.length}
    <div class="images-section-container my-3">
      <h4>Arrival Photos</h4>

      <div
        class="d-flex flex-column align-items-center"
        id="arrival-images-container">
        {#each $sidebarStore.parking_violation.images.arrival as arrival_image_url, index}
          <a
            class="parking-image-link"
            href={arrival_image_url.url}
            target="_blank"
            rel="noopener noreferrer">
            <img
              class="parking-image my-1"
              src={arrival_image_url.url}
              alt={`arrival image ${index}`} />
          </a>
        {/each}
      </div>
    </div>
  {/if}
  {#if $sidebarStore.parking_violation && $sidebarStore.parking_violation.images.departure.length}
    <div class="images-section-container my-3">
      <h4>Departure Photos</h4>
      <div
        class="d-flex flex-column align-items-center"
        id="departure-images-container">
        {#each $sidebarStore.parking_violation.images.departure as departure_image_url, index}
          <a
            class="parking-image-link"
            href={departure_image_url.url}
            target="_blank"
            rel="noopener noreferrer">
            <img
              class="parking-image my-1"
              src={departure_image_url.url}
              alt={`departure image ${index}`} />
          </a>
        {/each}
      </div>
    </div>
  {/if}
</div>
