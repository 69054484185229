<script>
  export let props = {};
</script>

<style>
  img {
    width: var(--cox2m-spacing-16-units);
    height: auto;
  }
</style>

{#if props.rideshare === 'uber'}
  <img src="images/uber-icon.png" alt="uber-logo" />
{:else if props.rideshare === 'lyft'}
  <img src="images/lyft-icon.png" alt="lyft-logo" />
{/if}
