<script>
  export let props;
</script>

<ul>

  {#each props.hardware as data}
  <li>{data}</li>
{/each}
</ul>
