import CurbsideStatusMonitoring from './forms/monitoring/CurbsideStatusMonitoring.svelte';
import MonitoringRedirect from './forms/monitoring/MonitoringRedirect.svelte';
import ParkingViolations from './forms/monitoring/ParkingViolations.svelte';
import CurbsideTaxiZoneReport from './forms/CurbsideTaxiZoneReport.svelte';
import HexnodeDevices from './forms/devices/HexnodeDevices.svelte';
import AlertsWrapper from './forms/alerts/AlertsWrapper.svelte';
import Monitoring from './forms/monitoring/Monitoring.svelte';
import CurbsideReport from './forms/CurbsideReport.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/alu-zone/:page?': CurbsideReport,
  '/taxi-zone/:page?': CurbsideTaxiZoneReport,
  '/alerts': AlertsWrapper,
  '/hexnode-devices': HexnodeDevices,
  '/parking-violations': ParkingViolations,

  '/monitoring': MonitoringRedirect,
  '/monitoring/log-table': Monitoring,
  '/monitoring/status': CurbsideStatusMonitoring,
  '/monitoring/parking-sessions': ParkingViolations,

  '*': NotFoundPage,
};

export const openRoutes = []

export default routes
