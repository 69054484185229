export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },

  {
    key: 'alu-zone',
    name: 'ALU Zone',
    icon: 'monitor',
    shouldShow: true,
    subItems: [
      {
        key: 'alu-zone-overview',
        path: '/alu-zone',
        name: 'Overview',
        icon: 'dashboard',
        shouldShow: true
      }
    ]
  },

  {
    key: 'taxi-zone',
    name: 'Taxi Zone',
    icon: 'loading-trolley',
    shouldShow: true,
    subItems: [
      {
        key: 'taxi-zone-overview',
        name: 'Overview',
        icon: 'dashboard',
        path: '/taxi-zone',
        shouldShow: true
      }
    ]
  },

  {
    key: 'parking-violations',
    path: '/parking-violations',
    name: 'Parking violations',
    icon: 'alert-circle',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-events-by-filter-parking')
  },
  {
    key: 'monitoring',
    name: 'Monitoring',
    icon: 'chart-line',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-events-using-filter'),
    subItems: [
      {
        key: 'hexnode-devices',
        path: '/hexnode-devices',
        name: 'D3 Devices',
        icon: 'modules',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-hardware-installation-data')
      },
      {
        key: 'Log',
        path: '/monitoring/log-table',
        name: 'Logs',
        icon: 'devices',
        shouldShow: true
      },
      {
        key: 'status',
        path: '/monitoring/status',
        name: 'Curbside Status',
        icon: 'activity',
        shouldShow: true
      },
      {
        key: 'parking-sessions',
        path: '/monitoring/parking-sessions',
        name: 'Parking Sessions',
        icon: 'note-text',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-events-by-filter-parking')
      },
    ]
  },
  {
    key: 'alerts',
    path: '/alerts',
    name: 'Alerts+Notifications',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-notifications-metrics-by-app-id')
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];
